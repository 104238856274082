import React, { useEffect } from "react"
import { Row } from "react-bootstrap"
import { ReadaAboutDetails } from "../common/dataJSON"
import about from "../assets/about.png"

export default function AboutDetails({ HideContent }) {
  useEffect(() => {
    window.scroll(0, 0)
  })
  return (
    <div>
      <Row>
        <div className="banner">
          <img src={about} className="img" alt="" />
          <i
            onClick={() => HideContent(false)}
            className="bi bi-arrow-left-short arrow"
          ></i>
          <h3 className="title"> {ReadaAboutDetails[0]?.title}</h3>
        </div>
      </Row>
      <Row className="content-row">
        <p
          className="content"
          dangerouslySetInnerHTML={{
            __html: ReadaAboutDetails[0]?.content,
          }}
        />
      </Row>
    </div>
  )
}
