import React, { useEffect } from "react"
import { Col, Row, Card } from "react-bootstrap"
import { Link } from "react-router-dom"

import Slider from "react-slick"
import { webreatheData } from "../common/dataJSON"
import Layout from "./Layout/layout"

export default function Webreathe() {
  const settings = {
    className: "center",
    centerMode: false,
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 600,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          rows: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: true,
          centerMode: false,
        },
      },
    ],
  }
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Layout>
      <div className="webreathe-container">
        <Link className="left-arrow" to={"/"}>
          <Link to={"/"}>
            <i className="bi bi-chevron-left"></i>
          </Link>
        </Link>
        <Row>
          <Col className="d-flex justify-content-center">
            <h3>We Breathe</h3>
          </Col>
        </Row>
        <Slider {...settings}>
          {webreatheData.map((item, index) => (
            <Row className="client-row" key={index}>
              <Col md={12} className="mt-4">
                <Card className="client-content">
                  <Card.Img src={item.img} className="client-img" />
                </Card>
              </Col>
            </Row>
          ))}
        </Slider>
      </div>
    </Layout>
  )
}
