let screenWidth = window.innerWidth

const updateScreenWidth = () => {
  screenWidth = window.innerWidth
}

const getScreenWidth = () => {
  return screenWidth
}

export { updateScreenWidth, getScreenWidth }
