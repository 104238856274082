import { Link, useNavigate, useParams } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useEffect } from "react"
import { forteData } from "../common/dataJSON"
import Layout from "./Layout/layout"

function ForteDetails() {
  const { id } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <Layout>
      <div className="solution-readmore" style={{ marginTop: "5rem" }}>
        <div className=" left-arrow ">
          <Link onClick={goBack}>
            <i className="bi bi-chevron-left"></i>
          </Link>
        </div>
        <Row className="solution-readcontent">
          <Col className="readmore-img">
            <img src={forteData[id]?.img} alt="" />
          </Col>
          <Col className="solution-paragraph">
            <Row>
              <h2>
                <span>{forteData[id]?.name}</span>
              </h2>
            </Row>
            <div className="solution-content-phara  pt-2">
              <p>{forteData[id]?.content}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}
export default ForteDetails
