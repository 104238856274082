import React from 'react'
import Layout from './Layout/layout'
import { Col, Row } from 'react-bootstrap'
import Altaf from '../teamMembers/ALTAF_PHOTOGRAPH.png'
import Sriram from '../teamMembers/SRIRAM.jpg'
import Ganesh from '../teamMembers/Ganesh_Acharya.jpg'
import Sardar from '../teamMembers/SARDAR_ALI_PHOTO.png'
import Murugavel from '../teamMembers/MURUGAVEL.png'
import Bala from '../teamMembers/BALAGANESAN.jpeg'
import Balasundaram from '../teamMembers/BALA_S.jpeg'
import Shafee from '../teamMembers/SHAFEE_1.jpeg'
import Dhanasekaran from '../teamMembers/DHANASEKARAPANDIAN.jpg'
import { Link } from 'react-router-dom'

function OurTeam() {
  return (
    <Layout>
      <div className="ourTeam_container">
        <Link
          className="left-arrow"
          to={'/'}>
          <Link to={'/'}>
            <i className="bi bi-chevron-left"></i>
          </Link>
        </Link>

        <div className="heading_grp">
          <div>
            <h6>OUR TEAM </h6>
            <h1>
              <span>Team</span> behind our success
            </h1>
          </div>
        </div>
        <div className="heading_grp">
          <div>
            <h3>LEADERSHIP TEAM </h3>
            <div className='line'></div>
          </div>
        </div>
        <div className="card-area card-area-1">
          <div className="d-flex align-item-center justify-content-center card_content">
            <div>
              <img
                className="card_img"
                src={Altaf}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Mir Althaf Hussain</h6>
                <p>Founder</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center card_content">
            <div>
              <img
                className="card_img"
                src={Sriram}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Sriram H </h6>
                <p>Founder & Chief Strategist</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center card_content">
            <div>
              <img
                className="card_img"
                src={Ganesh}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Dr.Ganesh Acharya </h6>
                <p>Director</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Sardar}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Dr.H.E.M.N Sardar Ali</h6>
                <p>Vice President</p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="heading_grp">
          <div>
            <h3>CORE TEAM </h3>
            <div className='line'></div>
          </div>
        </div>
        <div className="card-area card-area-2">
          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Murugavel}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>MURUGAVEL BALA</h6>
                <p>Chief Business Officer</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Bala}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Balaganesan K </h6>
                <p>Director - Business Automation</p>
              </Col>
            </Row>
          </div>

          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Balasundaram}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Bala Sundarasamy</h6>
                <p>Technical Advisor</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Shafee}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Shafee B</h6>
                <p>Head HR & Operations</p>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-item-center justify-content-center mt-4 card_content">
            <div>
              <img
                className="card_img"
                src={Dhanasekaran}
                alt="card1"
              />
            </div>
            <Row className="card_text_area">
              <Col>
                <h6>Dhanasekara Pandian</h6>
                <p>Sr.Executive Admin</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OurTeam
