import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { clientReadmoreData } from "../common/dataJSON"

export default function Automation({ isClientRead }) {
  useEffect(() => {
    window.scroll(0, 650)
  })
  return (
    <Container className="automation">
      <Row>
        <h2
          dangerouslySetInnerHTML={{
            __html: clientReadmoreData[isClientRead]?.title,
          }}
        ></h2>
      </Row>
      <Row className="container">
        <Col lg={5} md={12}>
          <img
            src={clientReadmoreData[isClientRead]?.img}
            width={"357px"}
            height={"333px"}
            alt=""
          />
        </Col>
        <Col lg={7} md={12}>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: clientReadmoreData[isClientRead]?.content,
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  )
}
