import React, { useState } from "react"
import India from "../assets/indiaMap.svg"
import Srilanka from "../assets/srilankaMap.jpeg"
import Japan from "../assets/japanMap.svg"
import Malaysia from "../assets/malaysiaMap.svg"
import Cambodia from "../assets/cambodiaMap.svg"
import Maldives from "../assets/maldivesMap.svg"
import UnitedStates from "../assets/USMap.jpeg"
import { Col, Row } from "react-bootstrap"
import DotIcon from "../assets/dot-icon.png"
import LocationIcon from "../assets/LocationIcon.svg"
import { Link } from "react-router-dom"

function WorldMapFinal() {
  const [hoverIndex, setHoverIndex] = useState(null)
  const countryData = [
    {
      id: 1,
      country: "India",
      image: India,
      address:
        "#Platinum Towers, First Floor,#11, Old Trunk Road, Pallavaram, Chennai – 600043, Tamilnadu, India",
      link: "https://www.google.com/maps/place/ATRIBS+SOFTWARE+SYSTEMS+PVT+LTD/@12.970437,80.1408913,15z/data=!4m10!1m2!2m1!1satribs+,43%2F11,+Unique+Paradise,+T.4,+3rd+Floor,+Thomas+Street,+Kadapperi,+Chennai-+600+045,+Tamilnadu+India.!3m6!1s0x3a525e31278708ab:0xd9a3721d121e2214!8m2!3d12.970437!4d80.151191!15sCmxhdHJpYnMgLDQzLzExLCBVbmlxdWUgUGFyYWRpc2UsIFQuNCwgM3JkIEZsb29yLCBUaG9tYXMgU3RyZWV0LCBLYWRhcHBlcmksIENoZW5uYWktIDYwMCAwNDUsIFRhbWlsbmFkdSBJbmRpYS6SARBzb2Z0d2FyZV9jb21wYW554AEA!16s%2Fg%2F11b7wd764_?entry=ttu",
    },
    {
      id: 2,
      country: "Srilanka",
      image: Srilanka,
      address:
        "#No : 1227/01, 6th Lane, Rajamalwatta Road, Battaramulla, 10120, Sri Lanka",
      link: "https://maps.google.com/maps?q=6.8976582%2C79.9185831&z=17&hl=en",
    },
    {
      id: 3,
      country: "Japan",
      image: Japan,
      address:
        "#CMA Consulting Inc, Kaminoge 4-2-2, Setagaya-ku, Tokyo 158-0093, Japan",
      link: "https://www.google.com/maps/search/CMA+Consulting+Inc,+Kaminoge+4-2-2,+Setagaya-ku,+Tokyo+158-0093,+Japan/@35.6129011,139.6268323,15z/data=!3m1!4b1?entry=ttu",
    },
    {
      id: 4,
      country: "Malaysia",
      image: Malaysia,
      address:
        "#Omega stellar(m) sdn bhd, #34 3rd floor, jln 9/23a Medan makmur, Setapak - 53200, Kuala Lumpur, Malaysia",
      link: "https://www.google.com/maps/search/Omega+stellar(m)+sdn+bhd,+34+3rd+floor,+jln+9%2F23a+Medan+makmur,+Setapak+-+53200+Kuala+Lumpur,+Malaysia/@3.1973089,101.7174905,17z/data=!3m1!4b1?entry=ttu",
    },
    {
      id: 5,
      country: "Cambodia",
      image: Cambodia,
      address:
        "#Sokha Phnom Penh Hotel & Residence, KeoChenda, Phum 1, Sangkat Chroy Changvar, Khan Chroy Changvar, Phnom Penh, Cambodia",
      link: "https://www.google.com/maps/place/Sokha+Phnom+Penh+Hotel/@11.5686138,104.9347041,17.12z/data=!4m13!1m2!2m1!1sSokha+Phnom+Penh+Hotel+%26+Residence,+KeoChenda,+Phum+1,+Sangkat+Chroy+Changvar,+Khan+Chroy+Changvar,+Phnom+Penh,+Cambodia!3m9!1s0x3109514b13bd5d97:0x55fdac143b4c4d57!5m2!4m1!1i2!8m2!3d11.568503!4d104.937482!15sCnhTb2toYSBQaG5vbSBQZW5oIEhvdGVsICYgUmVzaWRlbmNlLCBLZW9DaGVuZGEsIFBodW0gMSwgU2FuZ2thdCBDaHJveSBDaGFuZ3ZhciwgS2hhbiBDaHJveSBDaGFuZ3ZhciwgUGhub20gUGVuaCwgQ2FtYm9kaWGSAQVob3RlbOABAA!16s%2Fg%2F11dyxb9z1_?entry=ttu",
    },
    {
      id: 6,
      country: "Maldives",
      image: Maldives,
      address:
        "#M. Thaangeedhosuge, Ground Floor, Fenfiyaazu Goalhi, Male - 20211, Maldives",
      link: "https://www.google.com/maps/search/M.+Thaangeedhosuge,+Ground+Floor,+Fenfiyaazu+Goalhi,+Male+-+20211,+Maldives/@4.1857547,73.4859038,14z/data=!3m1!4b1?entry=ttu",
    },
    {
      id: 7,
      country: "United States",
      image: UnitedStates,
      address:
        "#Lehigh Valley Professional Center,2571 Baglyos Circle, Suite B-32,Bethlehem, PA 18020.",
      link: "https://maps.app.goo.gl/BncbAME91QBiLHXw9",
    },
  ]
  const handleMouseHover = (index) => {
    setHoverIndex(index)
  }
  const handleMouseOut = () => {
    setHoverIndex(null)
  }

  return (
    <div className="worldmap-addresses-container">
      <div className="Our-Global">
        <h1>Our Global Locations</h1>
        <img src={DotIcon} alt="dot" />
      </div>
      <Col className="cards">
        {countryData?.map((item, index) => (
          <div className="card-parent">
            <Row
              key={index}
              className={index === hoverIndex ? "card-hover" : "card"}
              onMouseEnter={() => handleMouseHover(index)}
              onMouseLeave={handleMouseOut}
            >
              <p className="text-content-country">{item?.country}</p>
              <p className="text-content-address">{item?.address}</p>
              <div className="map-image">
                <img src={item.image} alt="country locations" />
                <Link to={item.link} target="_blank">
                  <img
                    className="locationIcon"
                    src={LocationIcon}
                    alt="location icon"
                  />
                </Link>
              </div>
            </Row>
          </div>
        ))}
      </Col>
    </div>
  )
}

export default WorldMapFinal
