import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CompanyLogo from '../../assets/logo.svg'
import { OUR_TEAM, WEBREATHE_ROUTE } from '../../routes/routePath'

export default function Header({ HideContent }) {
  const [showNav, setShowNav] = useState(false)
  const [activeNav, setActiveNav] = useState('home')
  const goToElemment = (element) => {
    // HideContent(null)
    setActiveNav(element)
    let offset = 100
    if (document.getElementById(element)) {
      window.scrollTo({
        behavior: 'smooth',
        top: document.getElementById(element).getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      })
    }
    setShowNav(!showNav)
  }
  const navigate = useNavigate()
  const goToSolution = () => {
    navigate(`${WEBREATHE_ROUTE}`)
  }
  const goToOurTeam = () => {
    navigate(`${OUR_TEAM}`)
  }
  const location = useLocation()
  return (
    <Navbar className="header-content">
      <div className="container">
        <Link to="/">
          <img
            alt="Atribs"
            src={CompanyLogo}
          />
        </Link>
        <div>
          {showNav && (
            <ul className="header-section">
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('home')
                  setShowNav(true)
                }}
                className={`${activeNav === 'home' && 'active'}`}>
                <a>Home</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('forte')
                  setShowNav(true)
                }}
                className={`${activeNav === 'forte' && 'active'}`}>
                <a>Our Forte</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('solution')
                  setShowNav(true)
                }}
                className={`${activeNav === 'solution' && 'active'}`}>
                <a>Technology</a>
              </li>
              {/* <li
                onClick={() => goToElemment("partial")}
                className={`${activeNav === "partial" && "active"}`}
              >
                <a>Clients</a>
              </li> */}
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('partners')
                  setShowNav(true)
                }}
                className={`${activeNav === 'partners' && 'active'}`}>
                <a>Partners</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToSolution()
                  setShowNav(true)
                }}
                className={`${activeNav === 'about' && 'active'}`}>
                <a>
                  <Link
                    to={'/webreathe'}
                    onClick={() => setShowNav(true)}>
                    We Breathe
                  </Link>
                </a>
              </li>
              <li
                onClick={() => {
                  goToElemment('about')
                  setShowNav(true)
                }}
                className={`${activeNav === 'about' && 'active'}`}>
                <a>Who we are</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('career')
                  setShowNav(true)
                }}
                className={`${activeNav === 'career' && 'active'}`}>
                <a>Careers</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToElemment('contact')
                  setShowNav(true)
                }}
                className={`${activeNav === 'contact' && 'active'}`}>
                <a>Reach Us</a>
              </li>
              <li
                onClick={() => {
                  location.pathname === '/webreathe' ? navigate('/') : goToOurTeam()
                  setShowNav(true)
                }}
                className={`${activeNav === 'ourTeam' && 'active'}`}>
                <a>
                  <Link
                    to={'/ourTeam'}
                    onClick={() => setShowNav(true)}>
                    Our Team
                  </Link>
                </a>
              </li>
            </ul>
          )}
        </div>

        <div
          className={showNav ? 'hambarg-btn' : 'hambarg'}
          onClick={() => setShowNav(!showNav)}>
          <i className={`bi bi-${!showNav ? 'text-left' : 'x'}`}></i>
        </div>
      </div>
    </Navbar>
  )
}
