import React from "react"
import { Col, Row } from "react-bootstrap"
import { aboutDetails } from "../common/dataJSON"

export default function AboutUs({ setAbout }) {
  return (
    <>
      <Row className="aboutus-container">
        <div className="title">
          <h1>
            who we are: <span> "powered by people"</span>
          </h1>
        </div>
      </Row>
      {aboutDetails.map(({ name, img, content, id }) => (
        <Row key={id}>
          <div className="d-flex justify-content-center">
            <h3>{name}</h3>
          </div>
          <div>
            <img src={img} alt="" />
          </div>
          <p>{content}</p>
          {name === "ABOUT US" ? (
            <Row>
              <Col className="btn">
                <button className="inline-btn" onClick={() => setAbout(true)}>
                  {" "}
                  LEARN MORE{" "}
                </button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Row>
      ))}
    </>
  )
}
