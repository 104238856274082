import { Link, useNavigate, useParams } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { useEffect } from "react"
import { solutionDetailData } from "../common/dataJSON"
import Layout from "./Layout/layout"

function SolutionDetails() {
  const { id } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <Layout>
      <div className="solution-readmore" style={{ marginTop: "5rem" }}>
        <div className=" left-arrow ">
          <Link onClick={goBack}>
            <i className="bi bi-chevron-left"></i>
          </Link>
        </div>
        <Row className="solution-readcontent">
          <Col className="readmore-img">
            <img src={solutionDetailData[id]?.img} alt="" />
          </Col>
          <Col className="solution-paragraph">
            <Row>
              <h2>
                <span>{solutionDetailData[id]?.title}</span>
              </h2>
            </Row>
            <div className="solution-content-phara  pt-2">
              <p>{solutionDetailData[id]?.content}</p>
              <h3>{solutionDetailData[id]?.title1}</h3>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content1,
                }}
              ></p>
              <h3>{solutionDetailData[id]?.title2}</h3>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content2,
                }}
              ></p>
              <h3
                className={`${solutionDetailData[id]?.title3 ? "" : "d-none"}`}
              >
                {solutionDetailData[id]?.title3}
              </h3>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content3,
                }}
              ></p>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content4,
                }}
              ></p>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content5,
                }}
              ></p>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content6,
                }}
              ></p>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content7,
                }}
              ></p>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: solutionDetailData[id]?.content8,
                }}
              ></p>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}
export default SolutionDetails
