import React, { useState } from "react";
import Footer from "./footer";
import Header from "./header";

export default function Layout({ children, HideContent }) {
  const [activefooter, setActivefooter] = useState("home");
  const goToElemment = (element) => {
    setActivefooter(element);
    let offset = 100;
    if (document.getElementById(element)) {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.getElementById(element).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      });
    }
  };
  return (
    <div className="atrips-home">
      <Header HideContent={HideContent} />
      {children}
      <Footer goToElemment={goToElemment} />
    </div>
  );
}
