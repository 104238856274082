import React from "react";
import { Row, Col } from "react-bootstrap";
import Layout from "./Layout/layout";
import { useEffect } from "react";

// import { useNavigate } from "react-router-dom/dist";

function PrivacyPolicy() {
  //   const navigate = useNavigate();
  //   const handleNavigate = () => {
  //     navigate("/contactUs");
  //   };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="Address-data-container">
        <Col md={8} className="Contact-address">
          <Row style={{ position: "relative" }}>
            <div
              style={{
                marginTop: "2%",
                marginBottom: "5px",
                // marginLeft: "2%",
                padding: "10px",
                display: "flex",
                flexDirection: " row",
                alignItems: "baseline",
              }}
            >
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "#000000",
                }}
              >
                Privacy Policy
              </h1>
            </div>
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            This Privacy Policy explains how www.atribsglobal.com collects,
            uses, and protects any information that you provide when using our
            website www.atribsglobal.com or any associated services.
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Information We Collect
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            We may collect the following information:
          </Row>
          <Row>
            <Col lg="12" className="info" data-aos="fade-right">
              <ul>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  Personal information such as name, email address, contact
                  details, etc., when voluntarily submitted through forms or
                  account registration.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  Information automatically collected through cookies, log
                  files, or other tracking technologies about your usage of our
                  website, including but not limited to IP addresses, browser
                  type, pages visited, and time spent on the site.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  Any other information relevant to customer surveys, offers, or
                  promotions.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  The User hereby permits atribsglobal.com to send him/her
                  communication via Email/SMS/Voice/RCS/Whatsapp of any
                  updates/upgrades,notices,or any other information that
                  atribsglobal.com deems important for the user to know,relating
                  to the Products and/or Services
                </li>
              </ul>
            </Col>
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            How We Use Your Information
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            We use the information we collect for the following purposes:
          </Row>
          <Row>
            <Col lg="12" className="info" data-aos="fade-right">
              <ul>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  To provide and personalize our services according to your
                  needs.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  To improve our website and services based on your feedback.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  To send promotional emails about new products, special offers,
                  or other information that we think you may find interesting
                  using the email address provided.
                </li>
                <li
                  style={{
                    marginBottom: "5px",
                    display: "list-item",
                    listStyleType: "disc",
                    marginTop: "7px",
                  }}
                >
                  To communicate with you regarding inquiries, orders, or other
                  support requests.
                </li>
              </ul>
            </Col>
          </Row>

          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Data Security
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            We are committed to ensuring that your information is secure. We
            have implemented suitable physical, electronic, and managerial
            procedures to safeguard and secure the information we collect online
            to prevent unauthorized access or disclosure.
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Third-Party Disclosure
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            We do not sell, trade, or otherwise transfer your personally
            identifiable information to outside parties unless we provide you
            with advance notice. This does not include trusted third parties who
            assist us in operating our website, conducting our business, or
            servicing you, as long as those parties agree to keep this
            information confidential.
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Your Rights
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            You have the right to request access to the personal information we
            hold about you and to ask that your personal information be
            corrected, updated, or deleted. If you would like to exercise this
            right, please contact us using the information provided below.
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Changes to this Privacy Policy
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            We reserve the right to update or change our Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            updated Privacy Policy on this page.
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}
          >
            Contact Us
          </Row>
          <Row
            className="pt-3 mx-2"
            style={{ fontSize: "16px", lineHeight: "24px" }}
          >
            If you have any questions or concerns about this Privacy Policy,
            please contact us at
            <Col className="pt-0">
              <a
                href="mailto:letstalk@atribs.in"
                style={{
                  fontSize: "16px",
                  //   lineHeight: "24px",
                  textDecoration: "none",
                }}
              >
                letstalk@atribs.in
              </a>
            </Col>
          </Row>
        </Col>
        <Col md={4} className="contact-form p-0 mt-md-0 mt-sm-5"></Col>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
