import React from "react"
import { Row, Col } from "react-bootstrap"
import { HiOutlinePhone } from "react-icons/hi"
import { FiMail } from "react-icons/fi"
import Enquiry from "./enquiry"
import { mapLocations } from "../common/dataJSON"
import ContactMap from "../assets/contactMap.png"
import Pin from "../assets/pin.png"
import India from "../assets/india.png"
import Srilanka from "../assets/srilanka.png"
import Japan from "../assets/japan.png"
import Malaysia from "../assets/malaysia.png"
import Cambodia from "../assets/cambodia.png"
import Maldives from "../assets/maldives.png"
import { useNavigate } from "react-router-dom"
function Contact() {
  const handleCall = () => {
    window.open("tel:+91-44-42303063")
  }
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate("/contactUs")
  }
  return (
    <>
      <Col md={8} className="Contact-address">
        <Row style={{ position: "relative" }}>
          <img src={ContactMap} alt="Map" />
          {mapLocations.map(item => <img key={item.id} className="pin" onClick={handleNavigate} style={{ top: item.top, left: item.left }} src={Pin} alt="pin" />)}
        </Row>
        <Row className="pt-3 mx-2" style={{ fontWeight: "600", fontSize: "24px", lineHeight: "36px" }}>ATRIBS Software Systems PVT LTD</Row>
        <Row className="pt-3 mx-2" style={{ fontSize: "16px", lineHeight: "24px" }}>
          Platinum Towers, First Floor,#11, Old Trunk Road, Pallavaram,Chennai –
          600043,Tamilnadu, India
        </Row>
        <Row className="pt-3 mx-2">
          <Col style={{ padding: "0px" }}>
            <a
              onClick={handleCall}
              // href="tel:+91-44-42303063/64"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <HiOutlinePhone style={{ marginRight: "12px" }} />
            </a>
            <a
              onClick={handleCall}
              style={{ fontSize: "16px", lineHeight: "24px", textDecoration: "none" }}
            >
              {" "}
              +91-44-42303063/64
            </a>
          </Col>
        </Row>
        <Row className="pt-3 mx-2">
          <Col style={{ padding: "0px" }}>
            <a href="mailto:letstalk@atribs.in">
              {" "}
              <FiMail style={{ marginRight: "12px" }} />
            </a>

            <a
              href="mailto:letstalk@atribs.in"
              style={{ fontSize: "16px", lineHeight: "24px", textDecoration: "none" }}
            >
              {" "}
              letstalk@atribs.in
            </a>
          </Col>
        </Row>
        {/* <Row className="flags-head">
          <Col>
            <img
              className="box-shadow-flags"
              src={India}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
          <Col>
            <img
              className="box-shadow-flags"
              src={Srilanka}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
          <Col>
            <img
              className="box-shadow-flags"
              src={Malaysia}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
          <Col>
            <img
              className="box-shadow-flags"
              src={Japan}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
          <Col>
            <img
              className="box-shadow-flags"
              src={Maldives}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
          <Col>
            <img
              className="box-shadow-flags"
              src={Cambodia}
              onClick={handleNavigate}
              alt=""
            />
          </Col>
        </Row> */}
      </Col>
      <Col md={4} className="contact-form p-0 mt-md-0 mt-sm-5">
        <Enquiry />
      </Col>
    </>
  )
}

export default Contact
