import React, { useEffect } from "react"
import Layout from "./Layout/layout"
import { Col, Row } from "react-bootstrap"
import AddressIcon from "../assets/contactUsIconAddress.png"
import PhoneIcon from "../assets/contactUsIconPhone.png"
import EmailIcon from "../assets/contactUsIconEmail.png"
import WorldMapFinal from "./WorldMapFinal"
import BackgroundContact from "./BackgroundContact"

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <BackgroundContact />
      {/* <div className="contactus-container container">
        <div>
          <div className="text-container">
            <p className="contact-text-line1">Contact Us</p>
            <p className="contact-text-line2">We'd love to hear from you</p>
            <div className="responsive-container-design">
              <div className="flex-container-design">
                <div className="flex-container-text">
                  <p className="contact-text-line3">
                    we have offices and teams all&nbsp;
                    <span className="contact-text-line4">around the world</span>
                  </p>
                </div>
                <div className="spring-img">
                  <img alt="Design Spring" src={ContactUsSpring} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <WorldMapFinal />
      <div className="Address-data-container">
        <Row>
          <Col className="contactus-border">
            <div className="flex-details-container">
              <div>
                <img
                  alt="address"
                  src={AddressIcon}
                  className="image-conctact-icon-size"
                />
              </div>
              <div className="contactus-details-content">
                <p className="text-address">ADDRESS</p>
                <p className="text-address-details">
                  Platinum Towers, First Floor,#11, Old Trunk Road,
                  Pallavaram,Chennai – 600043,Tamilnadu, India
                </p>
              </div>
            </div>
          </Col>
          <Col className="contactus-border">
            <div className="flex-details-container">
              <div>
                <img
                  alt="address"
                  src={PhoneIcon}
                  className="image-conctact-icon-size"
                />
              </div>
              <div className="contactus-details-content">
                <p className="text-address">PHONE</p>
                <p className="text-address-details">+91-44-42303063/64</p>
              </div>
            </div>
          </Col>
          <Col className="contactus-border">
            <div className="flex-details-container">
              <div>
                <img
                  alt="address"
                  src={EmailIcon}
                  className="image-conctact-icon-size"
                />
              </div>
              <div className="contactus-details-content">
                <p className="text-address">E-MAIL</p>
                <p className="text-address-details">letstalk@atribs.in</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ContactUs
