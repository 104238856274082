import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import Slider from "react-slick"
import { useNavigate } from "react-router-dom"
import { solutionData } from "../common/dataJSON"
import { SOLUTION_ROUTE } from "../routes/routePath"
import { getScreenWidth } from "./utils/screen"

export default function Solutions() {
  const navigate = useNavigate()
  const [solutionRowOne, setSolutionRowOne] = useState()
  const [solutionRowTwo, setSolutionRowTwo] = useState()

  const settings = {
    // dots: true,
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          // unslick: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          // unslick: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // unslick: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // unslick: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  }
  const goToSolution = (id) => {
    console.log("id............", id)
    navigate(`${SOLUTION_ROUTE}/${id}`)
  }
  // solution row 1 & 2
  const getData = () => {
    const solution = [...solutionData]
    if (solution.length % 2 === 0) {
      const length = solution.length
      const rowValOne = solution.splice(0, length / 2)
      const rowValTwo = solution.slice(0, length - 1)
      setSolutionRowOne(rowValOne)
      setSolutionRowTwo(rowValTwo)
    } else {
      const length = solution.length
      const rowValOne = solution.splice(0, length / 2 + 1)
      const rowValTwo = solution.slice(0, length - 1)
      setSolutionRowOne(rowValOne)
      setSolutionRowTwo(rowValTwo)
    }
  }

  useEffect(() => {
    getData()
    // window.addEventListener("resize", handleResize)
  }, [])
  return (
    <>
      <Slider {...settings}>
        {solutionRowOne?.map(({ id, title, content, img }, index) => (
          <div key={index}>
            <Card className="" sm={12}>
              <Card.Img variant="" src={img} />
              <Card.Body className="">
                <h6 className="h5">{title}</h6>

                <Card.Text className="Text-content-line content h-4">
                  {content}
                </Card.Text>
                {index === solutionData.length ? (
                  ""
                ) : (
                  <span className="r-more" onClick={() => goToSolution(id)}>
                    Read More
                  </span>
                )}
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
      <Slider {...settings}>
        {solutionRowTwo?.map(({ id, title, content, img }, index) => (
          <div key={index}>
            <Card className="" sm={12}>
              <Card.Img variant="" src={img} />
              <Card.Body className="">
                <h6 className="h5">{title}</h6>

                <Card.Text className="Text-content-line content h-4">
                  {content}
                </Card.Text>
                {index === solutionData.length ? (
                  ""
                ) : (
                  <span className="r-more" onClick={() => goToSolution(id)}>
                    Read More
                  </span>
                )}
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
    </>
  )
}
