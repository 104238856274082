import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import BUTTONARROW from "../../assets/Button.svg";
import COMPANYLOGO from "../../assets/logo.svg";
import "../../index.scss";
import { MdKeyboardArrowUp } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Link } from "react-router-dom";

function Footer({ goToElemment }) {
  const backTo = (id) => {
    goToElemment(id);
  };
  const [showScroll, setShowScroll] = useState(false);
  const [newsLetter, setNewsLetter] = useState({
    message: "",
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  const goToTop = () => {
    window.scrollTo(0, 0);
  };
  window.addEventListener("scroll", checkScrollTop);
  const handleClick = () => {
    const newletter = {
      message: newsLetter,
    };
    if (newsLetter.message != "") {
      const serviceId = "service_o8ay9x6";
      const templateId = "template_k04sf6q";
      const YOUR_PUBLIC_KEY = "1jxERoRp8gRTEWl8A";
      const templateParams = newletter;

      emailjs.send(serviceId, templateId, templateParams, YOUR_PUBLIC_KEY).then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          if (response?.status === 200) {
            toast.success("Successfull");
          } else {
            toast.error("Failed");
          }
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    } else {
      toast.error("Please Fill The Letter");
    }
  };
  return (
    <div>
      <ToastContainer />
      <Row className="Footer-container">
        <Col md={4} sm={6} className="colum-1">
          <Row>
            <img className="company-logo" alt="logo" src={COMPANYLOGO} />
          </Row>
          <Row className="details">
            <Row>
              <address>
                <p className="Footer-address">
                  platinum Tower,First Floor,#11,Old Trunk Road,
                  Pallavaram,chennai-600043,TamilNadu,India
                </p>
              </address>
            </Row>

            <Col>
              <ul style={{ display: "flex" }} className="social-media">
                <li>
                  <a>
                    <i className="facebook ">
                      <i className="bi bi-facebook" />
                    </i>
                  </a>
                </li>
                <li>
                  <a>
                    <i className="instagram">
                      <i className="bi bi-instagram" />
                    </i>
                  </a>
                </li>
                <li>
                  <a
                    className="text-white"
                    href="https://www.linkedin.com/company/atribs/"
                    target="_blank"
                  >
                    <i className="linkedin">
                      <i className="bi bi-linkedin" />
                    </i>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col className="colum-2" md={4} style={{ flexWrap: "wrap" }}>
          <Row>
            <p className="company-name">Company</p>
          </Row>
          <Row>
            <a onClick={() => backTo("about")}>About</a>
          </Row>
          <Row className="mt-3">
            <a onClick={() => backTo("solution")}>Services</a>
          </Row>
          <Row className="mt-3">
            <a onClick={() => backTo("partners")}>Partners</a>
          </Row>
          <Row className="mt-3">
            <a onClick={() => backTo("career")}>Careers</a>
          </Row>
          <Row className="mt-3">
            <Link to={"/contactUs"}>Our Global Presence</Link>
          </Row>
          <Row className="mt-3">
            <Link to={"/privacyPolicy"}>Privacy Policy</Link>
          </Row>
          <Row className="mt-3">
            <Link to={"/termsAndConditions"}>Terms and Conditions</Link>
          </Row>
        </Col>
        <Col md={4} className="colum-3" col={2}>
          <Row>
            <p className="Newsletter">NewsLetter</p>
          </Row>

          <Row>
            <p>
              {" "}
              Keep yourself updated on the Global Digital Transformation
              Technology !!
            </p>
          </Row>
          <Row className=" input-box mt-3 p-0">
            <div className="d-flex justify-betweeen p-0">
              <Form.Control
                className="1px solid  footer-input"
                onChange={(e) => setNewsLetter(e.target.value)}
              />
              <img
                onClick={() => handleClick()}
                style={{ width: "81px", height: "60px", cursor: "pointer" }}
                src={BUTTONARROW}
              ></img>
            </div>
          </Row>
        </Col>
      </Row>
      <Row className="last-footer">
        <Col className="footer-last">
          <MdKeyboardArrowUp
            className="scrollTop"
            onClick={goToTop}
            style={{
              display: showScroll ? "flex" : "none",
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
