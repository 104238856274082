import Carousel from 'react-bootstrap/Carousel';
import { corouselData } from '../common/dataJSON';
import CoroselImage from '../assets/Home_Atribs_Infographics.png';

function CorouselContainer({ setIsClientRead }) {
    return (
        <div className='corousel-container d-flex justify-content-center position-relative'>
            <Carousel fade>
                {corouselData.map((item, index) =>
                    <Carousel.Item key={item.id} interval={2000}>
                        <img src={item.img} />
                        <Carousel.Caption>
                            <h3>{item.title}</h3>
                            <p>{item.subTitle}</p>
                        </Carousel.Caption>
                        <button className='button position-absolute' onClick={() => setIsClientRead(index)}>READ MORE..</button>
                    </Carousel.Item>)
                }
            </Carousel>
            <img src={CoroselImage} className='position-absolute' alt='' />
        </div>
    );
}

export default CorouselContainer;