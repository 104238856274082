import { Col, Row, Card } from "react-bootstrap"
import ACLogo from "../assets/ACLogo.svg"
import TCLogo from "../assets/TCLogo.svg"
import RCLogo from "../assets/RCLogo.svg"
import ICLogo from "../assets/ICLogo.svg"
import BCLogo from "../assets/BCLogo.svg"
import SCLogo from "../assets/SCLogo.svg"
import AOLogo from "../assets/AOLogo.png"
import TOLogo from "../assets/TOLogo.svg"
import ROLogo from "../assets/ROLogo.png"
import IOLogo from "../assets/IOLogo.png"
import BOLogo from "../assets/BOLogo.png"
import SOLogo from "../assets/SOLogo.png"
import chartLogo from "../assets/chartLogo.svg"
import { useState } from "react"
import { atribsDetail } from "../common/dataJSON"

export default function Atribs() {
  const [atribsDetails, setAtribsDetails] = useState(atribsDetail)

  const _renderData = (id, isShown) => {
    let atbDetails = [...atribsDetails]
    switch (id) {
      case 0:
        atbDetails[id].title = !isShown
          ? `Amalgam of quality, 
    results and people 
    centric approach`
          : "A"
        atbDetails[id].img = !isShown ? AOLogo : ACLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break
      case 1:
        atbDetails[id].title = !isShown
          ? `Team work targeting
          the pinnacle of client 
          satisfaction.`
          : "T"
        atbDetails[id].img = !isShown ? TOLogo : TCLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break
      case 2:
        atbDetails[id].title = !isShown
          ? `Robust work style to 
          Revolutionise 
          business practices.`
          : "R"
        atbDetails[id].img = !isShown ? ROLogo : RCLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break
      case 3:
        atbDetails[id].title = !isShown
          ? `Innovative 
          methodologies 
          combined with 
          Insightful 
          delivery.`
          : "I"
        atbDetails[id].img = !isShown ? IOLogo : ICLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break
      case 4:
        atbDetails[id].title = !isShown
          ? `Business 
          Transformation 
          & value addition 
          through IT.`
          : "B"
        atbDetails[id].img = !isShown ? BOLogo : BCLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break
      case 5:
        atbDetails[id].title = !isShown
          ? `Strategic Focus 
          coupled with shared 
          values to drive 
          delivery excellence.`
          : "S"
        atbDetails[id].img = !isShown ? SOLogo : SCLogo
        atbDetails[id].isShown = atbDetails[id].isShown ? false : true
        break

      default:
        break
    }
    setAtribsDetails(atbDetails)
  }

  const handleEnter = (id) => {
    _renderData(id)
  }

  const handleleave = (id) => {
    _renderData(id, "leave")
  }

  return (
    <>
      <Row className="w-100">
        {atribsDetails.map(({ id, title, img, isShown }) => (
          <Col className="" md={4} key={id}>
            <Card
              onMouseEnter={() => handleEnter(id)}
              onMouseLeave={() => handleleave(id)}
            >
              <Card.Img variant="top" src={img} className="w-auto" />
              <Card.Body
                className={`title ${!isShown ? "c-title" : "o-title"}`}
              >
                {isShown && <img className="chart" src={chartLogo} alt="" />}
                <span>{title}</span>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}
