import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { forteData } from "../common/dataJSON"
import { FORTE_DETAILS_ROUTE } from "../routes/routePath"
import { useNavigate } from "react-router-dom"

function ForteFinal() {
  const navigate = useNavigate()
  const handleClickLearn = (id) => {
    navigate(`${FORTE_DETAILS_ROUTE}/${id}`)
  }

  return (
    <div className="Forte-head-container-final">
      <Row>
        {forteData?.map((item, index) => (
          <Col key={index}>
            <Card className="container-forte-final">
              <>
                <img
                  src={item?.img}
                  alt="Avatar"
                  className="image-forte-overlay"
                />
                <div className="overlay-effect">
                  <div className="text-content">{item?.content}</div>
                  <div className="learn-more-text">
                    <button
                      className="learnMoreBtn"
                      onClick={() => handleClickLearn(index)}
                    >
                      Learn more
                    </button>
                    &nbsp;
                    <i className="bi bi-arrow-right"></i>
                  </div>
                  <div className="text-forte-overlay">{item?.name}</div>
                </div>
              </>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default ForteFinal
