import React from "react"
import "../index.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { Row, Col } from "react-bootstrap"
import { CAREERS } from "../images/images"

function Careers() {
  return (
    <Row className="career-container p-0">
      <Col sm={6} className="Career-content">
        <Row className="Career-content1">
          <p>
            <span>“Powered by People”</span> is installed into every associate
            at ATRIBS. Over the years our associates have made exemplary efforts
            in making us what we are today and shape our tomorrow. The stellar
            contributions from our associates over the years have been
            contributing to our steady growth.
          </p>
          <p>
            If you feel that you have the desire to challenge yourself every day
            at work with adventurous tasks, dynamic goals, and would like to
            charter your own path to success, then look no further share your cv
            to <a href="mailto:careers@atribs.in">careers@atribs.in</a>
          </p>
        </Row>
        <Row className=""></Row>
      </Col>
      <Col sm={6} className="careerLogo p-0">
        <img class="image-1" src={CAREERS} alt="" />
        <img class="image-2" src={CAREERS} alt="" />
        <img class="image-3" src={CAREERS} alt="" />
        <img class="image-4" src={CAREERS} alt="" />
      </Col>
    </Row>
  )
}

export default Careers
