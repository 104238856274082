import React from "react"
import { Col, Row, Card } from "react-bootstrap"
import Slider from "react-slick"
import { partnersData } from "../common/dataJSON"

export default function Partners() {
  const settings = {
    className: "center",
    // centerMode: true,
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    // slidesPerRow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          rows: 2,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          // arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          // arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  }

  return (
    <>
      <Row>
        <Col>
          <h3 className="title">PARTNERS LIST </h3>
        </Col>
      </Row>

      <Slider {...settings}>
        {partnersData.map((item, index) => (
          <Row className="client-row" key={index}>
            <Card className="client-content">
              <Card.Img src={item} className="partners-img" />
            </Card>
          </Row>
        ))}
      </Slider>
    </>
  )
}
